.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  /* /* position:absolute; */
  overflow-block: clip;

}

.map-container {
  /* height: 10rem; */
  /* height: 100vh; */
  /* width: 100%; */
  width: 100vw;
  /* align-content: center; */
  /* align-items: center; */
  /* display: flex; */
  height: 100vh;
  /* padding-top: 50px; */
  /* border: solid green; */
  margin: auto;
  
  /* flex-shrink: inherit; */
  position: relative;
}

/* .MapContainer {
  height: 100%;
  width: 100%;
  border: solid black;
} */

.home {
  /* /* height: 1%; */
  /* height: 100vh; */
  /* position: relative; */
  /* background-color: black; */
  /* position: absolute; */
  height: 100vh;
  width: 100vw;
  /* border: solid red; */
  position: inherit;
  /* padding-top: 50px; */
  /* min-height: auto; */
  
}
/* .modal-backdrop.in {
  opacity: 0;
} */

.modal-backdrop {
  opacity: .8 !important;
  /* display: none !important; */
}